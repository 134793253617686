import * as AWS from 'aws-sdk';
import { formatISO } from 'date-fns';
import { ConfigurationOptions } from 'aws-sdk';
import { useQuery } from '@tanstack/react-query';
import {
  AccessToken,
  LINKEDIN_REDIRECT_URL,
  LINKEDIN_AUTH_SESSION_KEY,
  useApi,
} from 'api';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  getSessionStorageValue,
  removeSessionStorageValue,
  setSessionStorageValue,
} from 'utils';

type LocalizedName = {
  en_US: string;
};

type PreferredLocale = {
  country: string;
  language: string;
};

type ProfileName = {
  localized: LocalizedName;
  preferredLocale: PreferredLocale;
};

type UserProfile = {
  firstName: ProfileName;
  id: string;
  lastName: ProfileName;
  localizedFirstName: string;
  localizedLastName: string;
  profilePicture: {
    displayImage: string;
  };
};

type UserContextValue = {
  login: () => void;
  logout: () => void;
  userSession?: AccessToken;
  userProfile?: UserProfile;
  loading: boolean;
};

type ConfigParams = {
  dynamoDbAccessKeyId: string;
  dynamoDbSecretAccessKey: string;
  linkedInClientId: string;
  linkedInClientSecret: string;
};

const UserContext = createContext<UserContextValue | null>(null);

export const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used within UserProvider');
  }

  return context;
};

type ProviderProps = {
  children: PropsWithChildren['children'];
};

export const DEFAULT_LOGIN_REDIRECT_URL = '/';

export const UserProvider = ({ children }: ProviderProps) => {
  const navigate = useNavigate();
  const { mandryszApi } = useApi();
  const [loading, setLoading] = useState(true);
  const initialUserSession = useMemo(() => {
    const data = getSessionStorageValue<AccessToken | undefined>(
      LINKEDIN_AUTH_SESSION_KEY,
      undefined,
    );
    return data;
  }, []);
  const [userSession, setUserSession] = useState<AccessToken | undefined>(
    initialUserSession,
  );
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>();
  const [searchParams, setSearchParams] = useSearchParams();
  const authorizationCode = searchParams.get('code');
  const [mandryszConfig, setMandryszConfig] = useState<ConfigParams | undefined>();

  useEffect(() => {
    if (
      !mandryszConfig ||
      !userProfile ||
      process.env.REACT_APP_DISABLE_DYNAMO_DB_LOGGING === 'true'
    ) {
      return;
    }
    const configuration: ConfigurationOptions = {
      region: 'eu-central-1',
      secretAccessKey: mandryszConfig.dynamoDbSecretAccessKey,
      accessKeyId: mandryszConfig.dynamoDbAccessKeyId,
      maxRetries: 13,
      retryDelayOptions: {
        base: 200, // exponential retry rate starting at 200ms
      },
    };
    AWS.config.update(configuration);
    const docClient = new AWS.DynamoDB.DocumentClient();

    // const getDataFromTable = (params: DocumentClient.ScanInput) =>
    //   docClient.scan(params).promise();

    const putItemToTable = async (
      params: AWS.DynamoDB.DocumentClient.PutItemInput,
    ) => {
      try {
        await docClient
          .put({
            Item: params.Item,
            TableName: params.TableName,
          })
          .promise();
      } catch (error) {
        console.error('Unable to connect to Dynamo DB', error);
      }
    };
    putItemToTable({
      Item: {
        id: userProfile.id,
        firstName: userProfile.localizedFirstName,
        lastName: userProfile.localizedLastName,
        dateOfVisit: formatISO(new Date()),
      },
      TableName: 'mandrysz',
    });
  }, [mandryszConfig, userProfile]);

  const login: UserContextValue['login'] = useCallback(() => {
    if (mandryszConfig?.linkedInClientId) {
      window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${mandryszConfig?.linkedInClientId}&redirect_uri=${LINKEDIN_REDIRECT_URL}&state=LlanvCinpuchAue6687FgychrEWWogiRVHrillvGEe7ndrobuch12&scope=r_liteprofile%20r_emailaddress`;
    }
  }, [mandryszConfig]);

  const logout = useCallback(() => {
    setUserSession(undefined);
    navigate('/login');
  }, [navigate]);

  useQuery({
    queryKey: ['configQuery'],
    queryFn: () => mandryszApi.get<ConfigParams>('/config'),
    onSuccess: ({ data }) => {
      setMandryszConfig(data);
    },
    onError: () => {
      console.error('Error: Unable to retrieve config data');
    },
  });

  useQuery({
    queryKey: ['accessTokenQuery'],
    queryFn: () =>
      mandryszApi.post<AccessToken>('/accesstoken', undefined, {
        params: new URLSearchParams({
          grant_type: 'authorization_code',
          code: authorizationCode ?? '',
          redirect_uri: LINKEDIN_REDIRECT_URL ?? '',
          client_id: mandryszConfig?.linkedInClientId ?? '',
          client_secret: mandryszConfig?.linkedInClientSecret ?? '',
        }),
      }),
    enabled: !!authorizationCode && !userSession && !!mandryszConfig,
    onSuccess: ({ data }) => {
      setUserSession(data);
    },
    onSettled: () => {
      setSearchParams(undefined);
      setLoading(false);
    },
    onError: () => {
      console.error('Error: Unable to login to LinkedIn');
    },
  });

  const getLinkedInUser = useCallback(async () => {
    if (!userSession) {
      return;
    }
    const { data } = await mandryszApi.get('/me', {
      headers: {
        Authorization: `Bearer ${userSession.access_token}`,
      },
    });
    setUserProfile(data);
    return data;
  }, [userSession, mandryszApi]);

  useQuery({
    queryKey: ['linkedInUserQuery'],
    queryFn: getLinkedInUser,
    enabled: !!userSession,
    onSettled: () => {
      setSearchParams(undefined);
      setLoading(!!authorizationCode);
    },
    onError: e => {
      console.error('Unable to retrieve LinkedIn user', e);
    },
  });

  useEffect(() => {
    if (userSession) {
      setSessionStorageValue(LINKEDIN_AUTH_SESSION_KEY, userSession);
    } else {
      removeSessionStorageValue(LINKEDIN_AUTH_SESSION_KEY);
    }
  }, [userSession]);

  useEffect(() => {
    if (!userSession) {
      setLoading(!!authorizationCode);
    }
  }, [userSession, authorizationCode]);

  return (
    <UserContext.Provider
      value={{ login, logout, userSession, userProfile, loading }}
    >
      {children}
    </UserContext.Provider>
  );
};
