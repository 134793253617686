import { Box, Paper } from '@mui/material';
import { LOGIN_REDIRECT_URL_KEY } from 'api';
import { AppBar, BottomNav } from 'components';
import { useUser } from 'contexts';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueries } from 'styles';
import { setSessionStorageValue } from 'utils';

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { userSession } = useUser();
  const { isMobile } = useQueries();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!userSession) {
      setSessionStorageValue(LOGIN_REDIRECT_URL_KEY, pathname);
      navigate('/login');
    }
  }, [userSession, navigate, pathname]);

  if (!userSession) {
    return null;
  }

  return (
    <>
      <AppBar />
      <Box
        sx={{
          mx: '0.75rem',
        }}
      >
        <Paper
          sx={{
            maxWidth: `calc(1000px - 2 * 1.5rem)`,
            margin: `${isMobile ? '6rem' : '8rem'} auto 3rem auto`,
            p: isMobile ? 0 : '3rem',
            borderRadius: '0.5rem',
          }}
          elevation={isMobile ? 0 : 5}
        >
          <Box>{children}</Box>
        </Paper>
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <BottomNav />
      </Box>
    </>
  );
};
