import AppBarMui from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { ThemeSwitch } from './theme-switch';
import { NavTabs } from './nav-tabs';
import { useQueries } from 'styles';
import { IconButton } from '@mui/material';
import { useMatch, useNavigate } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { ProfileBox } from './profile-box';

export const AppBar = () => {
  const { isMobile } = useQueries();
  const navigate = useNavigate();
  const isLoginPage = useMatch('/login');

  return (
    <Box sx={{ flexGrow: 1, m: 'auto' }}>
      <AppBarMui position="fixed" color="inherit">
        <Toolbar
          sx={{
            width: '100%',
            maxWidth: '1000px',
            display: 'flex',
            m: 'auto',
          }}
        >
          <Box
            sx={{
              mr: {
                sm: '3rem',
              },
            }}
          >
            <ProfileBox />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            {isMobile ? null : <NavTabs />}
          </Box>

          <Box sx={{ flexGrow: 1 }} />
          {process.env.NODE_ENV === 'development' ? (
            <IconButton
              sx={{
                mr: '1rem',
                color: isLoginPage ? '#0077b5' : undefined,
              }}
              onClick={() => {
                navigate('/login');
              }}
            >
              <LinkedInIcon />
            </IconButton>
          ) : null}
          <ThemeSwitch />
        </Toolbar>
      </AppBarMui>
    </Box>
  );
};
