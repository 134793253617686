import { CSSProperties, MutableRefObject, ReactNode, useState } from 'react';

import { Box } from '@mui/material';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { TimelineAccordion } from './timeline-accordion';
import { TIMELINE_DATA } from './timeline-data';
import { TRANSITION_DURATION, useQueries } from 'styles';

export type Job = {
  year?: number;
  logo: string;
  logoAlt: string;
  logoStyle?: CSSProperties;
  title: ReactNode;
  duration: ReactNode;
  technology: ReactNode;
  description: ReactNode;
};

export const Timeline = () => {
  const { isMobile } = useQueries();
  const initialExpandedItems = TIMELINE_DATA.map(({ title }) => ({
    title,
    expanded: false,
  }));
  const [expandedItems, setExpandedItems] = useState(initialExpandedItems);
  const [expandAll, setExpandAll] = useState(false);

  const handleExpandSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const shouldExpendAll = event.target.checked;
    setExpandAll(shouldExpendAll);
    setExpandedItems(prevItems =>
      prevItems.map(item => ({ ...item, expanded: shouldExpendAll })),
    );
  };

  const handleExpand =
    (title: Job['title'], ref: MutableRefObject<HTMLDivElement | null>) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedItems(prevExpanded => {
        const newItems = [...prevExpanded];
        return newItems.map(item => {
          if (item.title === title) {
            return {
              ...item,
              expanded: isExpanded,
            };
          }
          if (expandAll) {
            return item;
          }
          return { ...item, expanded: false };
        });
      });
      if (isMobile) {
        setTimeout(() => {
          const accordionRef = ref.current;
          if (accordionRef) {
            const headerOffset = 70;
            const elementPosition = accordionRef.getBoundingClientRect().top;
            const offsetPosition =
              elementPosition + window.pageYOffset - headerOffset;
            window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
          }
        }, TRANSITION_DURATION + 20);
      }
    };

  return (
    <Box position="relative">
      <FormGroup
        sx={{
          position: { sm: 'absolute' },
          top: { sm: '-2.9rem' },
          right: { sm: 0 },
          display: { xs: 'none', sm: 'flex' },
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={expandAll}
              onChange={handleExpandSwitch}
              name="expand-all-switch"
            />
          }
          label="Show All Project Details"
          sx={{ mr: 0 }}
        />
      </FormGroup>
      {TIMELINE_DATA.map(data => {
        const expanded = expandedItems.find(
          item => data.title === item.title,
        )?.expanded;
        return (
          <TimelineAccordion
            handleExpand={handleExpand}
            data={data}
            expanded={expanded}
            key={`${data.title}`}
          />
        );
      })}
    </Box>
  );
};
