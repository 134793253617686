import { Box, Typography } from '@mui/material';
import { Paragraph, ScreenLayout } from 'components';
import { CodeBox } from 'components';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const AlgorithmsScreen = () => {
  // 1. Zig-zag number validation:
  const getZigZag = (numbers: number[]): number[] => {
    let result = [];
    for (let i = 0; i < numbers.length; i++) {
      if (i > numbers.length - 3) {
        break;
      }
      if (numbers[i] < numbers[i + 1] && numbers[i + 1] > numbers[i + 2]) {
        result.push(1);
      } else if (numbers[i] > numbers[i + 1] && numbers[i + 1] < numbers[i + 2]) {
        result.push(1);
      } else {
        result.push(0);
      }
    }
    return result;
  };
  const zigzagCode = `const getZigZag = (numbers: number[]): number[] => {
    let result = [];
    for (let i = 0; i < numbers.length; i++) {
      if (i > numbers.length - 3) {
        break;
      }
      if (numbers[i] < numbers[i + 1] && numbers[i + 1] > numbers[i + 2]) {
        result.push(1);
      } else if (numbers[i] > numbers[i + 1] && numbers[i + 1] < numbers[i + 2]) {
        result.push(1);
      } else {
        result.push(0);
      }
    }
    return result;
}`;

  // 2. Find n-th largest number:
  const numbers = useMemo(() => [4, 100, 22, 2, 1, 0, 44], []);
  const [sortedNumbers, setSortedNumbers] = useState<number[]>([]);
  const targetPosition = 2;
  const [nthLargestNumber, setNthLargestNumber] = useState(0);
  const sortNumbersDescending = (numbers: number[]) => {
    const sorted = [...numbers].sort((a, b) => a - b).reverse();
    return sorted;
  };
  const findNLargestNumber = useCallback(
    (numbers: number[], targetPosition: number) => {
      // find nth largest number in the array
      return sortNumbersDescending(numbers)[targetPosition - 1];
    },
    [],
  );
  const findNLargestNumberCode = `const findNLargestNumber = (numbers: number[], targetPosition: number) => {
    const sorted = [...numbers].sort((a, b) => a - b).reverse();
    return sorted[targetPosition - 1];
}`;

  // 3. Reverse all words in a sentence:
  const sentenceToReverse = 'My name is Jakub';
  const [reversedSentence, setReversedSentence] = useState('');
  const reverseWords = (input: string) => {
    const words = input.split(' ');
    const reversed = words.map(word => word.split('').reverse().join(''));
    return reversed.join(' ');
  };
  const reverseWordsCode = `const reverseWords = (input: string) => {
    const words = input.split(' ');
    const reversed = words.map(word => word.split('').reverse().join(''));
    return reversed.join(' ');
  }`;

  // 4. Flatten an array:
  const arrayToFlatten = useMemo(() => [1, 2, [3, 4, [5], 6]], []);
  const [flattenedArray, setFlattenedArray] = useState<typeof arrayToFlatten>([]);
  const flattenArray = <T,>(input: T[]) => {
    let resultArray: T[] = [];
    const iterateItems = (items: T[]) => {
      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        if (Array.isArray(element)) {
          iterateItems(element);
        } else {
          resultArray.push(element);
        }
      }
    };
    iterateItems(input);
    return resultArray;
  };
  const flattenArrayCode = `const flattenArray = <T,>(input: T[]) => {
    let resultArray: T[] = [];
    const iterateItems = (items: T[]) => {
      for (let index = 0; index < items.length; index++) {
        const element = items[index];
        if (Array.isArray(element)) {
          iterateItems(element);
        } else {
          resultArray.push(element);
        }
      }
    };
    iterateItems(input);
    return resultArray;
}`;

  // Run all algorithms
  useEffect(() => {
    setReversedSentence(reverseWords(sentenceToReverse));
    setFlattenedArray(flattenArray(arrayToFlatten));
    setSortedNumbers(sortNumbersDescending(numbers));
    setNthLargestNumber(findNLargestNumber(numbers, targetPosition));
  }, [arrayToFlatten, sentenceToReverse, numbers, findNLargestNumber]);

  return (
    <ScreenLayout>
      <Typography variant="h1">Algorithms</Typography>

      <Box my="3rem">
        <Typography variant="h6">1. Zig-zag number validation:</Typography>

        <Paragraph>
          Let's say a triple (a, b, c) is a zigzag if either a {'<'} b {'>'} c or a{' '}
          {'>'} b {'<'} c.
        </Paragraph>

        <Paragraph>
          Given an array of integers numbers, our task is to check all the triples of
          its consecutive elements for being a zigzag. More formally, our task is to
          construct an array of length numbers.length - 2, where the ith element of
          the output array equals 1 if the triple (numbers[i], numbers[i + 1],
          numbers[i + 2]) is a zigzag, and 0 otherwise.
        </Paragraph>

        <Paragraph>
          For numbers = [1, 2, 1, 3, 4], the output should be the following:
        </Paragraph>
        <pre>{JSON.stringify(getZigZag([1, 2, 1, 3, 4]))}</pre>
        <ul>
          <li>
            (numbers[0], numbers[1], numbers[2]) = (1, 2, 1) is a zigzag, because 1{' '}
            {'<'} 2 {'>'} 1;
          </li>
          <li>
            (numbers[1], numbers[2] , numbers[3]) = (2, 1, 3) is a zigzag, because 2{' '}
            {'>'} 1 {'<'} 3;
          </li>
          <li>
            (numbers[2], numbers[3] , numbers[4]) = (1, 3, 4) is not a zigzag,
            because 1 {'<'} 3 {'<'} 4;
          </li>
        </ul>

        <Paragraph>
          For numbers = [1, 2, 3, 4], the output should be solution(numbers) = [0,
          0]. Since all the elements of numbers are increasing, there are no zigzags.
        </Paragraph>
        <Paragraph>
          For numbers = [1000000000, 1000000000, 1000000000], the output should be
          solution(numbers) = [0]. Since all the elements of numbers are the same,
          there are no zigzags.
        </Paragraph>

        <Paragraph>
          <b>Solution:</b>
        </Paragraph>
        <CodeBox>{zigzagCode}</CodeBox>
      </Box>

      <Box my="3rem">
        <Typography variant="h6">2. Find n-th largest number:</Typography>
        <Paragraph>We have an array like this:</Paragraph>
        <CodeBox>{JSON.stringify(numbers)}</CodeBox>
        <Paragraph>The value of n is: {targetPosition}</Paragraph>
        <Paragraph>We can sort the above array as follows:</Paragraph>
        <CodeBox>{JSON.stringify(sortedNumbers)}</CodeBox>
        <Paragraph>And the n-th largest number is: {nthLargestNumber}</Paragraph>
        <Paragraph>
          <b>Solution:</b>
        </Paragraph>
        <CodeBox>{findNLargestNumberCode}</CodeBox>
      </Box>

      <Box my="3rem">
        <Typography variant="h6">3. Reverse all words in a sentence:</Typography>
        <Paragraph>
          We have a sentence like this: {sentenceToReverse}. Our task is to reverse
          each word in the above sentence like so: {reversedSentence}
        </Paragraph>
        <Paragraph>
          <b>Solution:</b>
        </Paragraph>
        <CodeBox>{reverseWordsCode}</CodeBox>
      </Box>
      <Box my="3rem">
        <Typography variant="h6">4. Flatten an array:</Typography>
        <Paragraph>Let's take an array like this:</Paragraph>
        <Box>
          <pre>{JSON.stringify(arrayToFlatten)}</pre>
        </Box>
        <Paragraph>And flatten it.</Paragraph>
        <Box>Expected result:</Box>
        <pre>{JSON.stringify(flattenedArray)}</pre>
        <Paragraph>
          <b>Solution:</b>
        </Paragraph>
        <CodeBox>{flattenArrayCode}</CodeBox>
      </Box>
    </ScreenLayout>
  );
};
