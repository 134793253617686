export const getSessionStorageValue = <ValueType>(
  key: string,
  fallback: ValueType,
): ValueType => {
  const stored = sessionStorage.getItem(key);

  if (!stored) {
    return fallback;
  }

  try {
    return JSON.parse(stored);
  } catch (error) {
    throw new Error(`SessionStorage-Value could not be parsed: ${stored}`);
  }
};

export const setSessionStorageValue = <ValueType>(key: string, value: ValueType) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const removeSessionStorageValue = (key: string) => {
  sessionStorage.removeItem(key);
};
