import { Typography } from '@mui/material';
import { Exchange } from './exchange-utils';
import { formatNumberWithSpaces } from 'utils';
import { Table, Td, Th } from './exchange-table.styled';

type Props = {
  exchange: Exchange;
};
export const ExchangeTable = ({ exchange }: Props) => {
  return (
    <>
      <Typography variant="h3" textAlign="center">
        Exchange Rates
      </Typography>
      <Table>
        <thead>
          <tr>
            <Th>Currency</Th>
            <Th>Amount</Th>
            <Th isNumber>Rate</Th>
          </tr>
        </thead>
        <tbody>
          {exchange.currencies.map(item => (
            <tr key={item.code}>
              <Td align="center" isCurrency>
                {item.code}
              </Td>
              <Td isCurrency>{item.amount}</Td>
              <Td isNumber>{formatNumberWithSpaces(item.rate, 3)}</Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
