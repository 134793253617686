import { LinkedIn, Login } from '@mui/icons-material';
import { Box, Button, Paper, Typography } from '@mui/material';
import { LOGIN_REDIRECT_URL_KEY } from 'api';
import { ProfileBox } from 'components';

import { useUser } from 'contexts';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useQueries } from 'styles';
import { getSessionStorageValue, removeSessionStorageValue } from 'utils';

export const LoginScreen = () => {
  const navigate = useNavigate();
  const { isMobile } = useQueries();
  const { login, logout, userSession, loading } = useUser();
  const [redirectUrl, setRedirectUrl] = useState<string | undefined>();

  useEffect(() => {
    const savedRedirectUrl = getSessionStorageValue<string | undefined>(
      LOGIN_REDIRECT_URL_KEY,
      undefined,
    );

    if (savedRedirectUrl && userSession) {
      /**
       * User was just successfully authenticated using LinkedIn
       * and will be redirected to the original screen.
       */
      setRedirectUrl(savedRedirectUrl);
      removeSessionStorageValue(LOGIN_REDIRECT_URL_KEY);
    }
  }, [userSession]);

  if (loading) {
    return null;
  }

  if (redirectUrl && userSession) {
    return <Navigate to={redirectUrl} />;
  }

  return (
    <Box
      sx={{
        marginTop: { sm: 'calc(45vh - 120px)' },
        textAlign: 'center',
        px: { sm: '3rem' },
      }}
    >
      <Paper
        elevation={isMobile ? 0 : 3}
        sx={{
          display: 'flex',
          width: '100%',
          maxWidth: '50rem',
          my: { xs: '15rem', sm: '3rem' },
          mx: 'auto',
          py: { sm: '2rem' },
          justifyContent: 'center',
        }}
      >
        <Typography variant="h1" display="none">
          Jakub Mandrysz
        </Typography>
        <Box>
          {userSession ? (
            <>
              <Typography variant="body1">You're logged in.</Typography>

              <Box
                sx={{
                  mt: '1rem',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                }}
              >
                <Button
                  onClick={() => navigate('/')}
                  variant="contained"
                  size="large"
                  endIcon={<Login />}
                >
                  Continue
                </Button>
                <Button onClick={logout} variant="text" size="large">
                  Logout
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  mb: '1rem',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <ProfileBox />
              </Box>
              <Typography variant="body1">This website is private.</Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: '0.75rem', sm: 'inherit' },
                  mt: '0.5rem',
                  opacity: 0.5,
                }}
              >
                You need a LinkedIn account to access this page.
              </Typography>
              <Button
                variant="contained"
                onClick={login}
                startIcon={<LinkedIn />}
                size="large"
                sx={{ mt: '1rem' }}
              >
                Log in using LinkedIn
              </Button>
            </>
          )}
        </Box>
      </Paper>
    </Box>
  );
};
