import { Typography, TypographyProps } from '@mui/material';
import { PropsWithChildren } from 'react';

export const Text = ({
  children,
  ...props
}: PropsWithChildren & TypographyProps) => {
  return (
    <Typography variant="body2" component="span" {...props}>
      {children}
    </Typography>
  );
};
