import { Typography, TypographyProps } from '@mui/material';
import { PropsWithChildren } from 'react';

export const Paragraph = ({
  children,
  ...props
}: PropsWithChildren & TypographyProps<'p'>) => {
  return (
    <Typography variant="body3" component="p" {...props}>
      {children}
    </Typography>
  );
};
