import axios from 'axios';
import { useMemo } from 'react';

export type AccessToken = {
  access_token: string;
  expires_in: number;
  scope: 'r_emailaddress,r_liteprofile';
};

export const useApi = () => {
  const mandryszApi = useMemo(
    () =>
      axios.create({
        baseURL:
          process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_API_PROXY_MANDRYSZ
            : process.env.REACT_APP_API_MANDRYSZ,
      }),
    [],
  );

  return {
    mandryszApi,
  };
};
