import styled, { css } from 'styled-components';

const tableSpacing = css`
  padding: 0 0.3rem;
`;

export const Table = styled.table`
  font-size: 0.75rem;
`;

type CellProps = {
  isNumber?: boolean;
  isCurrency?: boolean;
};

export const Th = styled.th<CellProps>`
  ${tableSpacing}
  text-align: ${props => (props.isNumber ? 'right' : undefined)};
`;

export const Td = styled.td<CellProps>`
  ${tableSpacing}
  text-align: ${props => {
    if (props.isNumber) {
      return 'right';
    }
    if (props.isCurrency) {
      return 'center';
    }
  }};
  font-family: courier;
`;
