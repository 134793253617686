import { Box, SwitchProps, Switch as SwitchMui } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const Switch = (props: SwitchProps) => {
  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <SwitchMui {...props} />
      {!props.checked ? (
        <Box
          sx={{
            position: 'absolute',
            top: '13px',
            left: '33px',
            color: 'rgba(255, 255, 255, 0.7)',
            fontSize: '0.7rem',
          }}
        >
          <CloseIcon fontSize="inherit" />
        </Box>
      ) : null}
    </Box>
  );
};
