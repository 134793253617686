import Typography from '@mui/material/Typography';
import { Avatar, Box } from '@mui/material';
import jakubFace from 'assets/jakub-face.png';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(3.4)',
      opacity: 0,
    },
  },
}));

export const ProfileBox = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
        sx={{ mr: '1rem' }}
      >
        <Avatar
          alt="Jakub Mandrysz"
          src={jakubFace}
          sx={{ width: '50px', height: '50px' }}
        />
      </StyledBadge>

      <Typography
        noWrap
        component="div"
        sx={{
          lineHeight: '1.2rem',
          textTransform: 'uppercase',
          fontWeight: 500,
          fontSize: '1.25rem',
          textAlign: 'left',
        }}
      >
        <Box sx={{ letterSpacing: '0.18rem' }}>Jakub</Box>
        <Box sx={{ fontSize: '0.85rem', opacity: 0.5 }}>Mandrysz</Box>
      </Typography>
    </Box>
  );
};
