import {
  AutoFixHigh,
  Build,
  Computer,
  Email,
  EmojiEvents,
  LinkedIn,
  Phone,
  Public,
  TipsAndUpdates,
} from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonProps,
  Grid,
  LinkProps,
  Typography,
  useTheme,
} from '@mui/material';
import { Paragraph } from './paragraph';
import { Timeline } from './timeline';

/**
 * A4 size: 2480 x 3508 pixels
 */

export const Cv = () => {
  const theme = useTheme();
  const CvButton = (props: ButtonProps & LinkProps) => {
    return (
      <Button
        size="large"
        variant="text"
        fullWidth
        sx={{
          justifyContent: 'flex-start',
          color: theme.palette.text.primary,
          py: '0.2rem',
          '& .MuiButton-startIcon': {
            mr: '0.75rem',
          },
        }}
        {...props}
      >
        {props.children}
      </Button>
    );
  };

  return (
    <Box>
      <Grid container spacing={0}>
        <Grid item xs={12} md={6} mb={{ xs: '1.5rem', md: 0 }}>
          <Typography variant="h1">Jakub Mandrysz</Typography>
          <Typography variant="subtitle1">Frontend Developer</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <CvButton
              startIcon={<Email />}
              href="mailto:jakub@mandrysz.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              jakub@mandrysz.com
            </CvButton>
          </Box>
          <Box>
            <CvButton
              startIcon={<LinkedIn />}
              href="http://www.linkedin.com/in/jakubmandrysz"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.linkedin.com/in/jakubmandrysz
            </CvButton>
          </Box>
          <Box>
            <CvButton
              startIcon={<Public />}
              href="https://google.com/maps/place/Prague"
              target="_blank"
              rel="noopener noreferrer"
            >
              Prague, Czech Republic
            </CvButton>
          </Box>
          <Box>
            <CvButton startIcon={<Phone />} href="tel:+420728644921">
              +420 728 644 921
            </CvButton>
          </Box>
          <Box>
            <CvButton startIcon={<Computer />} href="https://mandrysz.com">
              mandrysz.com
            </CvButton>
          </Box>
        </Grid>
      </Grid>

      <Paragraph variant="body1" my={{ xs: '2.5rem', sm: '3rem' }}>
        I am a service-oriented web developer 💻 with 10+ years of experience 📚 in
        the field who works as an independent contractor for clients in Europe 🇪🇺 and
        the United States 🇺🇸. I'm offering a unique combination of creativity 🔥 and
        analytical skill 📐 with the ability to assess both vantage points to create
        cost-effective 📦 solutions. Currently focusing on React 🌐 development.
      </Paragraph>

      <Grid container spacing={2}>
        <Grid item sm={4}>
          <Box display="flex" alignItems="center">
            <TipsAndUpdates />
            <Typography variant="h6" ml="0.75rem">
              Skills
            </Typography>
          </Box>
          <Paragraph>
            React, Redux, HTML, SASS, Bootstrap, Typescript, NPM, GIT, Webpack, React
            Native, Gulp, Axios, BlueprintJS, SVN, RequireJS, Material UI, Angular,
            Jest, Nock, Jasmine
          </Paragraph>
        </Grid>
        <Grid item sm={4}>
          <Box display="flex" alignItems="center">
            <AutoFixHigh />
            <Typography variant="h6" ml="0.75rem">
              Design
            </Typography>
          </Box>
          <Paragraph>
            SPA, Styled Components, Progressive Enhancement, Adaptive, Responsive
            Design, BEM, OOCSS, DRY, Scrum, SoC, MVC, FLUX, OpenAPI, Material Design
          </Paragraph>
        </Grid>
        <Grid item sm={4}>
          <Box display="flex" alignItems="center">
            <Build />
            <Typography variant="h6" ml="0.75rem">
              Tools
            </Typography>
          </Box>
          <Paragraph>
            VS Code, AWS, NX, AWS Console, DynamoDB, Azure DevOps, Swagger,
            Screenfly, Photoshop, Inkscape, FA, Google Analytics, CRA, Icomoon,
            Serve, Bitbucket, Jira
          </Paragraph>
        </Grid>
      </Grid>
      <Typography variant="h2" display="flex" alignItems="center">
        <EmojiEvents sx={{ mr: '0.5rem' }} />
        Clients &amp; Projects
      </Typography>
      <Timeline />
    </Box>
  );
};
