import { alpha, Box, useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';

export const CodeBox = ({ children }: PropsWithChildren) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        fontSize: '0.8rem',
        bgcolor: alpha(theme.palette.text.primary, 0.05),
        p: '1rem',
        borderRadius: '0.25rem',
        overflow: 'auto',
      }}
    >
      <pre>{children}</pre>
    </Box>
  );
};
