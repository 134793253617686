type Currency = {
  code: string;
  rate: number;
  amount: number;
};

export type Exchange = {
  date: string;
  currencies: Currency[];
};

export const transformRateData = (data: string): Exchange => {
  const lines = data.split('\n');
  const keys: string[] = lines[1].split('|');
  const rates: Currency[] = [];

  for (let line = 2; line < lines.length; line++) {
    const rateValues = lines[line].split('|');
    const rateObject = {} as Currency;
    rateValues.forEach((item: string, index: number) => {
      const key = keys[index];
      let value: string | number = item;
      if (key === 'Amount') {
        value = parseFloat(item);
        rateObject.amount = value;
      }
      if (key === 'Rate') {
        value = parseFloat(item);
        rateObject.rate = value;
      }
      if (key && key === 'Code' && typeof value === 'string') {
        rateObject.code = value;
      }
    });
    if (Object.keys(rateObject).length > 0) {
      rates.push(rateObject);
    }
  }
  rates.sort((a, b) => {
    const codeA = a?.code?.toUpperCase();
    const codeB = b?.code?.toUpperCase();
    return codeA < codeB ? -1 : codeA > codeB ? 1 : 0;
  });

  return {
    date: lines[0],
    currencies: rates,
  };
};
