import { UserProvider } from 'contexts';
import { createBrowserRouter } from 'react-router-dom';
import {
  AlgorithmsScreen,
  ExchangeScreen,
  IntroScreen,
  LoginScreen,
  PhotofetcherScreen,
  RootScreen,
} from 'screens';
import { ProtectedRoute } from './protected-route';

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <UserProvider>
        <RootScreen />
      </UserProvider>
    ),
    children: [
      {
        path: '/login',
        element: <LoginScreen />,
      },
      {
        index: true,
        element: (
          <ProtectedRoute>
            <IntroScreen />
          </ProtectedRoute>
        ),
      },
      {
        path: '/algorithms',
        element: (
          <ProtectedRoute>
            <AlgorithmsScreen />
          </ProtectedRoute>
        ),
      },
      {
        path: '/exchange',
        element: (
          <ProtectedRoute>
            <ExchangeScreen />
          </ProtectedRoute>
        ),
      },
      {
        path: '/photofetcher',
        element: (
          <ProtectedRoute>
            <PhotofetcherScreen />
          </ProtectedRoute>
        ),
      },
      {
        path: '*',
        element: <></>,
      },
    ],
  },
]);
