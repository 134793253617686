/**
 * Required for MUI Lab:
 * https://mui.com/material-ui/about-the-lab/#typescript
 */
import type {} from '@mui/lab/themeAugmentation';

import { PaletteMode, ThemeOptions } from '@mui/material';

export const TRANSITION_DURATION = 250; // milliseconds

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }

  interface Palette {
    photofetcherPrimary: Palette['primary'];
  }

  interface PaletteOptions {
    photofetcherPrimary?: PaletteOptions['primary'];
  }

  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
}
// add to props

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

// add to components
declare module '@mui/material/Typography' {
  interface TypographyClasses {
    body3: string;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    photofetcherPrimary: true;
  }
}

declare module '@mui/material/Switch' {
  interface SwitchPropsColorOverrides {
    photofetcherPrimary: true;
  }
}

/**
 * Inspired by:
 * https://stackoverflow.com/questions/71092293/dark-mode-in-react-using-mui-v5
 */
export const getMuiTheme = (mode: PaletteMode): ThemeOptions => ({
  palette: {
    mode,
    photofetcherPrimary: {
      main: '#6E4BCD',
      contrastText: '#fff',
    },
  },
  transitions: {
    getAutoHeightDuration: () => TRANSITION_DURATION,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: () => ({
    h1: {
      fontSize: '2.3rem',
      marginBottom: '1rem',
      fontWeight: 400,
    },
    h2: {
      fontSize: '1.4rem',
      marginBottom: '1rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.2rem',
      marginBottom: '1rem',
    },
    button: {
      textTransform: 'none',
      lineHeight: 1.2,
    },
    body2: {
      fontSize: '1rem',
      margin: '0.5rem 0 0.5rem 0',
    },
    body3: {
      display: 'block',
      margin: '1rem 0 1rem 0',
    },
  }),
});

export const getMuiComponents = (): { components: ThemeOptions['components'] } => ({
  components: {
    MuiTypography: {
      styleOverrides: {
        body3: ({ theme }) => ({
          [theme.breakpoints.only('xs')]: {
            margin: '0.5rem 0 1.5rem 0',
          },
        }),
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        pre {
          margin: 0;
          padding: 0;
        }
        body {
          margin: 0;
          overflow-y: scroll;
          word-break: break-word;
        }
      `,
    },
    MuiGrid: {
      styleOverrides: {
        container: ({ theme }) => ({
          marginBottom: '1.5rem',
          [theme.breakpoints.only('xs')]: {
            marginBottom: '1.5rem',
          },
        }),
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          '@media (min-width: 0px)': {
            '@media (orientation: landscape)': {
              minHeight: '56px',
            },
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            minHeight: '48px',
            marginTop: 0,
            marginBottom: 0,
          },
        },
        content: {
          '&.Mui-expanded': {
            marginTop: 0,
            marginBottom: 0,
          },
        },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: 'transparent',
          boxShadow: 'none',
          [theme.breakpoints.only('xs')]: {
            width: '2.25rem',
          },
        }),
      },
    },
    MuiTimelineConnector: {
      styleOverrides: {
        root: ({ theme }) => ({
          display: 'none',
          backgroundColor: theme.palette.mode === 'dark' ? '#424242' : undefined,
        }),
      },
    },
    MuiTimelineSeparator: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.only('xs')]: {
            display: 'block',
            order: 1,
            marginRight: '0.75rem',
          },
        }),
      },
    },
    MuiTimelineItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.only('xs')]: {
            flexWrap: 'wrap',
          },
        }),
      },
    },
    MuiTimelineOppositeContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.only('xs')]: {
            textAlign: 'left',
            marginTop: '0.8rem',
            order: 2,
          },
        }),
      },
    },
    MuiTimelineContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          display: 'flex',
          alignItems: 'center',
          margin: 0,
          '& > .MuiTypography-root': {
            margin: 0,
          },
          [theme.breakpoints.only('xs')]: {
            padding: 0,
            margin: 0,
            order: 3,
            flexBasis: '100%',
          },
        }),
      },
    },
  },
});
