import diabloLogo from 'assets/diablo-logo.png';
import dbgLogo from 'assets/dbg-logo.png';
import pwcLogo from 'assets/pwc-logo.png';
import cezLogo from 'assets/cez-logo.png';
import deloitteLogo from 'assets/deloitte-logo.png';
import absaLogo from 'assets/absa-logo.png';
import ncrLogo from 'assets/ncr-logo.png';
import hbLogo from 'assets/hb-logo.png';
import aspectworksLogo from 'assets/aspectworks-logo.png';
import { Job } from './timeline';
import { Paragraph } from './paragraph';

export const TIMELINE_DATA: Job[] = [
  {
    year: 2023,
    logo: pwcLogo,
    logoAlt: 'PwC Logo',
    logoStyle: { transform: 'scale(0.9)' },
    title: 'Junction Platform',
    duration: <>07/2017 &ndash; 01/2023</>,
    technology: 'React.js · Redux.js · TypeScript · SASS · CSS Modules · Veracode',
    description: (
      <>
        <Paragraph>
          First joined PwC in July 2017, and co-worked on several SPAs (Deals,
          Productivity Hub, DAC6) using Typescript or Javascript. These ReactJS or
          Angular apps were intended for auditors and their clients. Developed a wide
          variety of project components including Redux forms, HOCs, Styled
          Components and Bootstrap 4 and SASS themes. There was 8+ people involved on
          these Scrum projects; 3 developers worked on the front-end including
          myself. Also wrote tests using Jest and Nock. The development ended in June
          2019.
        </Paragraph>
        <Paragraph>
          In January 2021, PwC asked me to rejoin them again to build a brand-new
          project called the Junction. This new digital platform was developed by
          more than 50 developers divided into smaller teams. I introduced a few
          concepts and set some standards for high quality code including CSS modules
          or Material UI. Some of my highlights on this project include working with
          Excel API (Excel Add-in), PowerBI API, Tableau API, PowerPivot sharing
          (dynamic object ingestion), improving the code security using Veracode, and
          rapid prototyping including several proofs of concepts.
        </Paragraph>
      </>
    ),
  },
  {
    year: 2022,
    logo: dbgLogo,
    logoAlt: 'Deutsche Börse Logo',
    logoStyle: { transform: 'scale(0.9)' },
    title: 'Xact & Business Monitoring',
    duration: <>10/2016 &ndash; 12/2022</>,
    technology:
      'nx.dev · json-schema-forms · React.js · Material-UI · Open API · TypeScript',
    description: (
      <>
        <Paragraph>
          Started at Deutsche Börse in October 2016 and created Business Monitoring
          Scheduler from scratch, a single-page app using MVC approach, Observer
          pattern and Adaptive design. Solved the challenge of having thousands of
          data records being rendered at a time by applying appropriate styles and
          behavior of individual components. This project was done by June 2017.
        </Paragraph>
        <Paragraph>
          I came back to Deutsche Borse in January 2018 to help develop other
          business monitoring applications including Bridge Transmission Reports, Key
          Performance Indicator Bridge, Settlement Efficiency Reports, Alert System,
          CLM. ReactJS frontend development with the following tech stack:
          Typescript, latest CRA, Material UI, React Hooks, OpenAPI Fetch Generators.
        </Paragraph>
        <Paragraph>
          In 2022, I set up a team of 5 developers to work on a new Xact UI project
          using the mentioned technology. Also conducted technical interviews for
          Deutsche Borse and Clearstream in Luxembourg.
        </Paragraph>
      </>
    ),
  },
  {
    year: 2021,
    logo: deloitteLogo,
    logoAlt: 'Deloitte Logo',
    title: 'Digital Asset Management',
    duration: <>05/2021 &ndash; 07/2022</>,
    technology:
      'Amazon Web Services (AWS) · Google Maps API · WebSocket · React.js · TypeScript',
    description: (
      <Paragraph>
        Deloitte asked me to lead a team of four frontend and full-stack developers.
        Responsible for the delivery of a React application that facilitates asset
        management. Set coding and formatting standards for the UI codebase. Solved
        iPad and Safari specific frontend issues as these kinds of devices were
        mainly used by Daimler and its clients. Transformed design prototypes into
        reality in a challenging time frame. Integrated Google Maps API and
        geo-location features to display assets on Google Maps. Customized Recharts
        to display Grundfos pump data in real time using WebSockets.
      </Paragraph>
    ),
  },
  {
    year: 2020,
    logo: cezLogo,
    logoAlt: 'CEZ Logo',
    logoStyle: { transform: 'scale(0.8)' },
    title: 'Distribution Rate Process App',
    duration: <>03/2020 &ndash; 10/2020</>,
    technology: 'React · Typescript · Material UI · API wrappers · contexts',
    description: (
      <Paragraph>
        Delivered a frontend app written in React using Material UI and the latest
        development principles. Optimized for mobile devices as well. I was the main
        frontend developer in charge and had one other junior developer who helped me
        expand the codebase. The most interesting part of this app include the global
        handling of errors and loading states through API wrappers which I developed
        specifically for this project plus OpenAPI contracts. We didn’t want to use
        Redux, so we used React context instead.
      </Paragraph>
    ),
  },
  {
    year: 2019,
    logo: absaLogo,
    logoAlt: 'Absa Logo',
    title: 'Prime Portal',
    duration: <>11/2019 &ndash; 07/2020</>,
    technology: 'Angular 5 · Typescript · Redux · Celine · Solar UI · Gridster',
    description: (
      <Paragraph>
        Redesigned and rebuilt Prime Portal from scratch using corporate Javascript
        tools. Based on Angular version 5, Celine was used for this project because
        it was added as a new module (like microservice paradigm). For the styling
        and theming, the Solar UI library was implemented. Solo development on the
        frontend, one backend developer, one business analyst. So only a small team
        of 3 developers. Integrated Gridster, a grid drag-and-drop component as a
        special feature of this project.
      </Paragraph>
    ),
  },
  {
    year: 2018,
    logo: ncrLogo,
    logoAlt: 'NCR Logo',
    logoStyle: { transform: 'scale(0.7)' },
    title: 'Aloha Sales & Ordering',
    duration: <>06/2019 &ndash; 03/2020</>,
    technology:
      'JavaScript · React Native · Redux · Typescript · Styled Components · Android',
    description: (
      <Paragraph>
        Developed a React Native application used by millions of restaurants across
        the United States and worldwide for taking orders and hospitality management.
        We had a huge team of about 70 members working from Atlanta in the United
        States and Prague in the Czech Republic split into groups of 5. Shipped an
        Android app inspired by Material UI principles.
      </Paragraph>
    ),
  },
  {
    year: 2015,
    logo: hbLogo,
    logoAlt: 'Hypotecni Banka Logo',
    logoStyle: { transform: 'scale(0.9)' },
    title: 'Mortgage Client Zone',
    duration: <>10/2015 &ndash; 09/2016</>,
    technology:
      'AngularJS · Bootstrap 3 · SASS · Gulp · Node.js · FontForge · CSS Sprites · Responsive & Adaptive Design, SVN',
    description: (
      <Paragraph>
        A mortgage management project for clients of Hypotecni banka, a financial
        institution based in Prague, Czech Republic. The Client Zone, built in
        AngularJS and managed through node.js (npm), and Gulp, facilitates the
        mortgage management by a user-friendly interface including a unique
        combination of adaptive and responsive web design. Responsible for the visual
        implementation of the design and responsiveness, which was achieved by using
        SASS, Bootstrap, and a custom designed file structure.
      </Paragraph>
    ),
  },
  {
    year: 2014,
    logo: aspectworksLogo,
    logoAlt: 'Aspectworks Logo',
    logoStyle: { transform: 'scale(0.7)' },
    title: 'SnowMirror Deimos Hithit',
    duration: <>06/2014 &ndash; 09/2015</>,
    technology:
      'WordPress · Bootstrap 3 · SASS · Javascript · Mobile Optimization · Photoshop',
    description: (
      <Paragraph>
        I joined Aspectworks to help them develop the following applications:
        <br />
        <br />
        Guidevision SnowMirror
        <br />
        June 2014 – September 2015
        <br />
        http://www.snow-mirror.com/
        <br />
        Revamped the website appearance and introduced WordPress. Fully integrated
        the content management system and built a custom designed WordPress theme.
        Custom built plugins. Set up Google Analytics goals and implemented Events
        for tracking downloads. Designed signatures for all members of the team.
        Connected the registration forms with Salesforce using PHP, SOAP and
        SalesforceSoapClient, wrote custom plugins for that purpose. Built the custom
        jQuery tabs and the homepage form. Set up social media and designed covers.
        <br />
        <br />
        Deimos Gantt Chart
        <br />
        March 2015 – June 2015
        <br />
        The projects managers use this Gantt Chart for project planning and employee
        allocation management. Integrated the Gantt Chart into the Salesforce
        environment and connected with the allocations table. The project required a
        combination of several JavaScript libraries, including canvg and html2canvas,
        which were used to print PDF files based on the JavaScript generated and user
        modified data.
        <br />
        <br />
        Hithit Redesign
        <br />
        May 2015 – July 2015
        <br />
        https://www.hithit.com/
        <br />
        Coded and designed several aspects of the new Hithit website using the latest
        CSS rules and trends. The website’s fully responsive and its user interface
        utilizes the modern approach. The Hithit project has adopted some of the
        Bootstrap best practices such as the notification system.
        <br />
        <br />
        Active24 GUI
        <br />
        September 2014 – October 2014
        <br />
        https://www.active24.cz/
        <br />
        GUI Library Implementation. Designed new user interface changes. Merged new
        CSS library with the existing one. New action buttons implemented. Overall
        look refreshed.
        <br />
        <br />
        ServiceNow Setup for i Mobile Platform
        <br />
        July 2014 – September 2014
        <br />
        ServiceNow Mobile Application. Responsible for the complete ServiceNow setup
        of the mobile platform, which then directly communicates with Android mobile
        devices. Created and wrote the scripts for the new ServiceNow application.
      </Paragraph>
    ),
  },
  {
    year: 2004,
    logo: diabloLogo,
    logoAlt: 'Diablo Logo',
    logoStyle: { transform: 'scale(0.7)' },

    title: 'Blizzard Fansite Partner',
    duration: <>09/2004 &ndash; 06/2014</>,
    technology:
      'HTML · CSS · PHP · Bootstrap · MySQL · JavaScript · AJAX · Custom CMS · Photoshop',
    description: (
      <Paragraph>
        Designed and created the DiabloFans.cz website using its own CMS. News
        integration with Facebook and Twitter automatically. Formed a community of 5
        000 members. Increased Diablo III sales in the Czech Republic and promoted
        the products nationwide. Set up hosting and published. Led a team of 3
        editors to create high quality content. Earned official authorization from
        Blizzard Entertainment to run the site. Website visits counter has reached 8
        000 000 and continues to grow.
      </Paragraph>
    ),
  },
];
