import { alpha, Box, Grid, useTheme } from '@mui/material';
import { Text } from './text';
import { Paragraph } from './paragraph';
import { History } from '@mui/icons-material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Job } from './timeline';
import { MutableRefObject, useRef } from 'react';

type Props = {
  expanded?: boolean;
  handleExpand: (
    title: Job['title'],
    ref: MutableRefObject<HTMLDivElement | null>,
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  data: Job;
};

export const TimelineAccordion = ({
  expanded,
  handleExpand,
  data: { year, logo, logoAlt, logoStyle, title, duration, technology, description },
}: Props) => {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement | null>(null);
  return (
    <Accordion
      ref={ref}
      key={`${title}`}
      expanded={expanded}
      onChange={handleExpand(title, ref)}
      sx={{
        border: 0,
        boxShadow: 0,
        background: 'transparent',
        mx: { sm: '-1rem' },
        mb: { xs: '1rem', sm: 0 },
        '&::before': {
          display: 'none',
        },
        '&.Mui-expanded': {
          margin: { xs: 0, sm: '0 -1rem' },
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          height: { xs: 'auto', sm: '60px' },
          borderRadius: '0.25rem',
          '& .MuiAccordionSummary-content': {
            my: 0,
          },

          [theme.breakpoints.only('xs')]: {
            textAlign: 'center',
            background: alpha(theme.palette.text.primary, 0.05),
            py: '0.5rem',
            '& .MuiGrid-root': {
              mb: 0,
            },
          },
          '&:hover': {
            background: alpha(theme.palette.text.primary, 0.1),
          },
        }}
      >
        <Grid
          container
          spacing={0}
          sx={{
            mb: 0,
          }}
          alignItems="center"
        >
          <Grid item xs={2} sm={1}>
            {year}
          </Grid>
          <Grid
            item
            xs={10}
            sm
            sx={{
              px: { sm: '0.75rem' },
              py: { xs: '0.25rem', sm: 0 },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                py: { sm: '0.5rem' },
              }}
            >
              <img
                src={logo}
                alt={logoAlt}
                style={{
                  width: 'auto',
                  maxWidth: '100%',
                  maxHeight: '40px',
                  filter:
                    theme.palette.mode === 'dark'
                      ? 'brightness(50%) grayscale(1) invert(1)'
                      : undefined,
                  ...logoStyle,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} display={{ xs: 'none', sm: 'block' }}>
            <Text sx={{ fontWeight: 500 }}>{title}</Text>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Grid container>
          <Grid item sm={1} sx={{ p: 0 }}></Grid>
          <Grid item sm={2} sx={{ p: 0 }}></Grid>
          <Grid item sm={9} sx={{ p: '0 0 0 0.2rem' }}>
            <Text
              sx={{
                fontWeight: 500,
                display: { xs: 'block', sm: 'none' },
                my: { xs: '1rem' },
              }}
            >
              {title}
            </Text>
            <Paragraph variant="body2" mb="0.9rem">
              <History sx={{ verticalAlign: 'top', mt: '-0.1rem', mr: '0.3rem' }} />
              {duration}
            </Paragraph>
            <Paragraph variant="body2">
              <Text
                sx={{
                  display: { xs: 'block', sm: 'inline' },
                  fontWeight: 'bold',
                }}
              >
                Skills:
              </Text>{' '}
              {technology}
            </Paragraph>
            {description}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
