import { GlobalStyles as GlobalStylesMui } from '@mui/material';

export const GlobalStyles = () => (
  <GlobalStylesMui
    styles={() => ({
      // html: {
      //   scrollBehavior: 'smooth',
      // },
      // body: {
      //   overflow: 'auto',
      //   '@media (hover: hover) and (pointer: fine)': {
      //     // overflowY: 'scroll',
      //   },
      // },
    })}
  />
);
