import React, { useContext, useMemo } from 'react';
import { Button, IconButton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LightModeIcon from '@mui/icons-material/LightMode';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import { PaletteModeContext } from 'styles';
import { useQueries } from 'styles';

export const ThemeSwitch = () => {
  const theme = useTheme();
  const { isMobile } = useQueries();
  const colorMode = useContext(PaletteModeContext);

  const icon = useMemo(() => {
    return theme.palette.mode === 'dark' ? <BedtimeIcon /> : <LightModeIcon />;
  }, [theme.palette.mode]);

  if (useMediaQuery(theme.breakpoints.down('sm'))) {
    return (
      <IconButton color="inherit" onClick={colorMode.toggleColorMode}>
        {icon}
      </IconButton>
    );
  }

  return (
    <Button
      onClick={colorMode.toggleColorMode}
      variant="outlined"
      color="inherit"
      startIcon={icon}
      sx={{ textTransform: 'capitalize' }}
    >
      {isMobile ? null : <>{theme.palette.mode} mode</>}
    </Button>
  );
};
