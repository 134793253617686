import { Tab, Tabs } from '@mui/material';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'router';
import { CurrencyExchange } from '@mui/icons-material';

/**
 * Inspired by:
 * https://codesandbox.io/s/365j0v?file=/demo.js
 */

export const NavTabs = () => {
  // You need to provide the routes in descendant order.
  // This means that if you have nested routes like:
  // users, users/new, users/edit.
  // Then the order should be ['users/add', 'users/edit', 'users'].
  const routeMatch = useRouteMatch([
    '/',
    '/algorithms',
    '/exchange',
    '/photofetcher',
  ]);

  const currentTab = routeMatch?.pattern?.path;

  const minWidth = '7rem';

  return (
    <Tabs value={currentTab} variant="scrollable" scrollButtons="auto">
      <Tab
        label="Profile"
        value="/"
        to="/"
        component={Link}
        icon={<AccessibilityNewIcon />}
        iconPosition="start"
        sx={{
          minWidth,
        }}
      />
      <Tab
        label="Algorithms"
        value="/algorithms"
        to="/algorithms"
        component={Link}
        icon={<DeveloperModeIcon />}
        iconPosition="start"
        sx={{
          minWidth,
        }}
      />
      <Tab
        label="Exchange"
        value="/exchange"
        to="/exchange"
        component={Link}
        icon={<CurrencyExchange />}
        iconPosition="start"
        sx={{ minWidth }}
      />
      <Tab
        label="Fetcher"
        value="/photofetcher"
        to="/photofetcher"
        component={Link}
        icon={<CameraAltIcon />}
        iconPosition="start"
        sx={{ minWidth }}
      />
    </Tabs>
  );
};
