import { DoubleArrow, FolderZip } from '@mui/icons-material';
import {
  Alert,
  alpha,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Paragraph, ScreenLayout } from 'components';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useQueries } from 'styles';
import { formatNumberWithSpaces } from 'utils';
import { ExchangeTable } from './exchange-table';
import { useExchange } from './use-exchange';

const API_URL =
  'https://www.cnb.cz/en/financial-markets/foreign-exchange-market/central-bank-exchange-rate-fixing/central-bank-exchange-rate-fixing/daily.txt';

const DOCS_URL =
  'https://www.cnb.cz/en/faq/Format-of-the-foreign-exchange-market-rates/';

export const ExchangeScreen = () => {
  const theme = useTheme();
  const { isMobile } = useQueries();
  const { isFetching, error, data } = useExchange();

  const [currency, setCurrency] = useState<string | null>(null);
  const [amount, setAmount] = useState('');
  const [resultAmount, setResultAmount] = useState<number>();
  const [resultCurrency, setResultCurrency] = useState<string | null>();

  const MAX_WIDTH = useMediaQuery(theme.breakpoints.up('md')) ? '12rem' : '100%';

  const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9\b.,]+$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      setAmount(event.target.value);
    }
  };

  const currencyOptions = useMemo(
    () => data?.currencies.map(item => item.code) || [],
    [data],
  );

  const handleConvertClick = useCallback(() => {
    const rateItem = data?.currencies?.find(item => item.code === currency);
    if (rateItem && typeof rateItem.rate === 'number') {
      const amountValue = parseFloat(amount);
      const convertedValue = (amountValue / rateItem.rate) * rateItem.amount;
      setResultAmount(convertedValue);
      setResultCurrency(currency);
    }
  }, [data, amount, currency]);

  return (
    <ScreenLayout>
      <Typography variant="h1">Currency Exchange</Typography>
      <Box
        sx={{
          bgcolor: alpha(theme.palette.text.primary, 0.05),
          p: '1rem',
          borderRadius: '0.5rem',
        }}
      >
        <Typography variant="h2">Assignment:</Typography>

        <Paragraph>
          Create a simple React app (don't use NextJS please), which:
        </Paragraph>

        <Box>
          <ol>
            <li>
              When it starts, retrieve the latest currency exchange rates from the
              Czech National Bank.
              <ul>
                <li>
                  API URL:{' '}
                  <Link href={API_URL} rel="noopener noreferrer" target="_blank">
                    {API_URL}
                  </Link>
                </li>
                <li>
                  Documentation:{' '}
                  <Link href={DOCS_URL} rel="noopener noreferrer" target="_blank">
                    {DOCS_URL}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              Parses the downloaded data and clearly displays it to the user in the
              UI.
            </li>
            <li>
              Add a simple form, into which the customer can enter an amount in CZK
              and select a currency, and after clicking a button sees the amount
              entered in CZK converted into the selected currency.
            </li>
            <li>
              Commit your code throughout your work and upload the resulting codebase
              into a Github repo.
            </li>
            <li>
              Tech stack: React (+Hooks), TypeScript, Styled Components, React Query.
            </li>
          </ol>
          <Paragraph>
            Overall: Keep the code simple and the UI nice and easy to use for the
            user.
          </Paragraph>
        </Box>
      </Box>
      <Box mb="3rem">
        <Stack
          sx={{
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            my: '3rem',
          }}
        >
          <Typography variant="h2" textAlign="center">
            Solution Live Demo:
          </Typography>
          <Button
            size="large"
            component={RouterLink}
            to={`${process.env.PUBLIC_URL}/currency-converter.zip`}
            endIcon={<FolderZip />}
            variant="contained"
            target="_blank"
            download
          >
            Download Solution (zip)
          </Button>
        </Stack>

        {isFetching ? (
          <Box
            sx={{
              height: 682,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        ) : null}

        {error ? (
          <Alert severity="error">Error while fetching the data.</Alert>
        ) : null}

        {!error && data && !isFetching ? (
          <Stack
            sx={{
              flexDirection: {
                xs: 'column-reverse',
                sm: 'row',
              },
              alignItems: 'center',
              justifyContent: 'center',
              gap: { xs: '2rem', sm: '5rem' },
            }}
          >
            <Box>
              <ExchangeTable exchange={data} />
            </Box>
            <form
              onSubmit={e => {
                e.preventDefault();
                handleConvertClick();
              }}
            >
              <Stack
                sx={{
                  flexDirection: {
                    xs: 'column',
                  },
                }}
                gap="1rem"
              >
                <FormControl fullWidth sx={{ maxWidth: MAX_WIDTH }}>
                  <InputLabel htmlFor="amount">Amount</InputLabel>
                  <OutlinedInput
                    autoFocus={!isMobile}
                    id="amount"
                    value={amount}
                    onChange={handleAmountChange}
                    endAdornment={
                      <InputAdornment position="end">CZK</InputAdornment>
                    }
                    label="Amount"
                    sx={{ '& .MuiInputBase-input': { textAlign: 'right' } }}
                  />
                </FormControl>

                <Autocomplete
                  value={currency}
                  disabled={currencyOptions.length === 0}
                  disablePortal
                  id="currency"
                  options={currencyOptions}
                  fullWidth
                  sx={{ maxWidth: MAX_WIDTH }}
                  getOptionLabel={option => option || ''}
                  onChange={(event: any, newValue: string | null) => {
                    setCurrency(newValue);
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Convert to" />
                  )}
                />
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={!currency || !amount}
                  endIcon={<DoubleArrow />}
                  onClick={handleConvertClick}
                  sx={{ maxWidth: MAX_WIDTH, minHeight: '56px' }}
                >
                  Convert
                </Button>

                <FormControl fullWidth sx={{ maxWidth: MAX_WIDTH }}>
                  <InputLabel htmlFor="result">Converted Amount</InputLabel>
                  <OutlinedInput
                    readOnly
                    id="result"
                    value={`${
                      resultAmount ? formatNumberWithSpaces(resultAmount) : ''
                    }`}
                    endAdornment={
                      <InputAdornment position="end">
                        {resultCurrency}
                      </InputAdornment>
                    }
                    label="Converted Amount"
                    sx={{ '& .MuiInputBase-input': { textAlign: 'right' } }}
                  />
                </FormControl>

                <Typography
                  variant="body1"
                  textAlign="center"
                  fontSize="0.75rem"
                  color="GrayText"
                >
                  Data accurate as of
                  <br />
                  {data.date}
                </Typography>
              </Stack>
            </form>
          </Stack>
        ) : null}
      </Box>
    </ScreenLayout>
  );
};
