import { useQuery } from '@tanstack/react-query';
import { useApi } from 'api';
import { Exchange, transformRateData } from './exchange-utils';

export const useExchange = () => {
  const { mandryszApi } = useApi();

  const getExchangeRates = async () => {
    const response = await mandryszApi.get('/cnb');
    const data = response.data;
    return transformRateData(data);
  };

  return useQuery<Exchange>({
    queryKey: ['getExchangeRatesQuery'],
    queryFn: getExchangeRates,
  });
};
