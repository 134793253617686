import { useCallback, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import {
  Alert,
  Typography,
  Stack,
  FormGroup,
  FormControlLabel,
  Grid,
  Button,
  Box,
  useTheme,
  alpha,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useApi } from 'api';
import { shuffle } from 'utils';
import { useQueries } from 'styles';
import { ImageBox, ImageItem, Paragraph, Switch } from 'components';
import { ScreenLayout } from 'components/screen-layout';
import photofetcher from 'assets/photofetcher.jpg';

export const PhotofetcherScreen = () => {
  const theme = useTheme();
  const { mandryszApi } = useApi();
  const { isMobile } = useQueries();

  const getPhotos = () => {
    return mandryszApi.get('/aukro').then(res => res.data);
  };

  const [images, setImages] = useState<Array<ImageItem>>([]);
  const [isGrayscale, setIsGrayscale] = useState(false);

  const { isFetching, error, data, refetch, fetchStatus } = useQuery({
    queryKey: ['photoQuery'],
    queryFn: getPhotos,
    select: (data): ImageItem[] => {
      const crazyPricesItems = data.mainPart.find(
        ({ type }: any) => type === 'CrazyPricesItems' || type === 'HotAuctions',
      );
      const images = crazyPricesItems?.content.items.map((item: any) => ({
        name: item.name,
        url: item.images.lists.large[0].url,
      }));
      return images;
    },
  });

  const handleFetchNewPhotos = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleGrayscaleSwitch = useCallback(() => {
    setIsGrayscale(prev => !prev);
  }, []);

  useEffect(() => {
    if (data && fetchStatus === 'idle') {
      const newImages = shuffle(data);
      const preparedImages = newImages.slice(0, 4).map(item => ({ ...item }));
      setImages(preparedImages);
    } else {
      setImages([]);
    }
  }, [data, fetchStatus]);

  return (
    <ScreenLayout>
      <Typography variant="h1">Photo Fetcher</Typography>
      <Box
        sx={{
          bgcolor: alpha(theme.palette.text.primary, 0.05),
          p: '1rem',
          borderRadius: '0.5rem',
        }}
      >
        <Typography variant="h2">Assignment:</Typography>
        <Paragraph>
          This is a simple front-end code challenge created to serve two purposes:
        </Paragraph>
        <ol>
          <li>Show off my skills</li>
          <li>Give a better understanding of my skills</li>
        </ol>
        <Paragraph>The programming challenge has 2 parts:</Paragraph>
        <ul>
          <li>Part A: HTML + CSS</li>
          <li>Part B: Typescript</li>
        </ul>
        <Typography variant="h2">Part A: HTML + CSS</Typography>
        <img src={photofetcher} alt="Assignment" width="50%" />
        <ul>
          <li>Create a layout based on the given screen above.</li>
          <li>
            Style the layout consistently and beautifully, adhering as closely to the
            design as possible.
          </li>
          <li>
            NOTE: I am free to use any preprocessor you wish, or write raw CSS. Bonus
            points for an explanation.
          </li>
        </ul>
        <Typography variant="h2">Part B: Typescript</Typography>
        <ul>
          <li>
            Consume this public API @ https://aukro.cz/backend/api/homepage to
            retrieve a list of photos
          </li>
          <li>
            introPart -{'>'} CrazyPricesItems -{'>'} content -{'>'} items -{'>'}{' '}
            images -{'>'} ... -{'>'} url
          </li>
          <li>
            cors problem = try localhost:4200 or deal with it, there are multiple
            options how to do it when backend is not finished yet, but the structure
            is already set
          </li>
          <li>
            Display random 4 photos in the HTML + CSS layout created in step A,
            sticking to the layout, placing data where it fits, responsive.
          </li>
          <li>
            Toggle switch - while enabled, turns all the visible photos to be
            grayscale.
          </li>
          <li>
            Button “Fetch New Photos” will display a new set of random 4 photos.
          </li>
          <li>Use React framework</li>
          <li>Dockerization & hot reload inside Docker is a Bonus.</li>
        </ul>
      </Box>

      <Typography variant="h2" my="3rem">
        Solution Live Demo:
      </Typography>

      <Grid
        container
        spacing={3}
        mb={isMobile ? '2rem' : '1rem'}
        direction={isMobile ? 'column-reverse' : undefined}
      >
        <Grid item xs={isMobile ? 12 : true}>
          <Stack direction="row" alignItems="center">
            <FormGroup>
              <FormControlLabel
                disabled={images.length === 0}
                control={
                  <Switch
                    checked={isGrayscale}
                    onChange={handleGrayscaleSwitch}
                    inputProps={{ 'aria-label': 'Grayscale switch' }}
                    color="photofetcherPrimary"
                  />
                }
                label="Make photos grayscale"
              />
            </FormGroup>
          </Stack>
        </Grid>
        <Grid
          item
          xs={isMobile ? 12 : true}
          display="flex"
          sx={{
            alignItems: 'center',
            justifyContent: isMobile ? undefined : 'flex-end',
          }}
        >
          <Button
            variant="contained"
            onClick={handleFetchNewPhotos}
            disabled={isFetching}
            fullWidth={isMobile}
            color="photofetcherPrimary"
          >
            Fetch New Photos
          </Button>
        </Grid>
      </Grid>
      {error ? (
        <Alert severity="error" sx={{ mb: '1rem' }}>
          Error while fetching data
        </Alert>
      ) : null}
      {!isFetching && images.length === 0 && !error ? (
        <Alert severity="info">There are no data available</Alert>
      ) : (
        <Grid container spacing={2}>
          {images.map(item => {
            return (
              <ImageBox
                isGrayscale={isGrayscale}
                key={item.id || v4()}
                {...item}
                isLoading={isFetching}
              />
            );
          })}
        </Grid>
      )}
    </ScreenLayout>
  );
};
