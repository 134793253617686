import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { useRouteMatch } from 'router';
import { CurrencyExchange } from '@mui/icons-material';

export const BottomNav = () => {
  const navigate = useNavigate();
  const routeMatch = useRouteMatch([
    '/',
    '/algorithms',
    '/exchange',
    '/photofetcher',
  ]);
  const currentTab = routeMatch?.pattern?.path;

  return (
    <Box sx={{ pb: 7 }}>
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          '& .MuiBottomNavigationAction-label, & .MuiBottomNavigationAction-label.Mui-selected':
            { fontSize: '0.7rem' },
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={currentTab}
          onChange={(event, newValue) => {
            navigate(newValue);
            document.documentElement.scrollTo(0, 0);
          }}
          sx={{
            backgroundImage:
              'linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09))',
          }}
        >
          <BottomNavigationAction
            value="/"
            label="Profile"
            icon={<AccessibilityNewIcon />}
          />
          <BottomNavigationAction
            value="/algorithms"
            label="Algorithms"
            icon={<DeveloperModeIcon />}
          />
          <BottomNavigationAction
            value="/exchange"
            label="Exchange"
            icon={<CurrencyExchange />}
          />
          <BottomNavigationAction
            value="/photofetcher"
            label="Fetcher"
            icon={<CameraAltIcon />}
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
};
