import {
  CssBaseline,
  PaletteMode,
  ThemeProvider,
  useMediaQuery,
  responsiveFontSizes,
  createTheme,
} from '@mui/material';

import { deepmerge } from '@mui/utils';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import {
  getMuiTheme,
  getMuiComponents,
  PaletteModeContext,
  GlobalStyles,
} from 'styles';
import { RouterProvider } from 'react-router-dom';
import { router } from 'router';
import { useEffect, useMemo, useState } from 'react';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const App = () => {
  const [mode, setMode] = useState<PaletteMode>('dark');
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const muiTheme = useMemo(() => {
    const theme = createTheme(deepmerge(getMuiTheme(mode), getMuiComponents()));
    return responsiveFontSizes(theme);
  }, [mode]);

  const paletteMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode(prev => (prev === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  useEffect(() => {
    setMode(prefersDarkMode ? 'dark' : 'light');
  }, [prefersDarkMode]);

  return (
    <PaletteModeContext.Provider value={paletteMode}>
      <ThemeProvider theme={muiTheme}>
        <QueryClientProvider client={queryClient}>
          <CssBaseline />
          <GlobalStyles />
          <RouterProvider router={router} />
        </QueryClientProvider>
      </ThemeProvider>
    </PaletteModeContext.Provider>
  );
};
