import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Box, Grid, Link, Skeleton } from '@mui/material';
import { useQueries } from 'styles';

export type ImageItem = {
  id?: number;
  name?: string;
  url?: string;
};

type Props = ImageItem & { isLoading: boolean; isGrayscale: boolean };

export const ImageBox = ({ isLoading, isGrayscale, url, name }: Props) => {
  const ref = useRef<HTMLImageElement>(null);
  const [imageSize, setImageSize] = useState<number | undefined>(undefined);
  const { isMobile } = useQueries();
  const BAR_SIZE = 75;

  const updateSize = () => {
    const width = ref.current?.offsetWidth;
    setImageSize(width);
  };

  useEffect(() => {
    updateSize();
  }, [ref]);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <Grid item xs={isMobile ? 12 : 6}>
      <Box sx={{ boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.3)' }}>
        <Box
          ref={ref}
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: imageSize ? `${imageSize - BAR_SIZE}px` : undefined,
            overflow: 'hidden',
          }}
        >
          {isLoading || !imageSize ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="100%"
              height="100%"
            />
          ) : (
            <img
              src={url}
              alt={name}
              title={name}
              style={{
                width: '100%',
                height: 'auto',
                filter: isGrayscale ? 'grayscale(1)' : undefined,
              }}
            />
          )}
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '100%',
              color: '#fff',
              bottom: 0,
              height: `${BAR_SIZE}px`,
              background: 'rgba(0, 0, 0, 0.7)',
              p: '0.7rem',
            }}
          >
            {isLoading || !imageSize ? (
              <Box width="100%">
                <Skeleton
                  animation="wave"
                  variant="text"
                  sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.21)',
                  }}
                />
                <Skeleton
                  animation="wave"
                  variant="text"
                  width="75%"
                  sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.21)',
                  }}
                />
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    fontSize: '1.2rem',
                  }}
                >
                  {name}
                </Box>
                <Box
                  sx={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    color: 'rgba(255, 255, 255, 0.6)',
                  }}
                >
                  <Link
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="inherit"
                    sx={{
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline',
                        color: 'rgba(255, 255, 255, 1)',
                      },
                    }}
                  >
                    {url}
                  </Link>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};
